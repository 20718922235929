import styled from "styled-components";
import logo from "../assets/img/phone.png";

export default function About() {
  return (
    <Container>
      <LeftSide>
        <Title>
          ABOUT <GreenText>OUR</GreenText> <PurpleText>MISSION</PurpleText>
        </Title>
        <Text>
          We’re bridging the gap between mobile and Web3 by fostering a
          community built on trust, learning, and growth. Through camaraderie,
          cutting-edge education, robust security, and unmatched opportunities,
          we empower a new era of decentralized mobile experiences.
        </Text>
      </LeftSide>
      <RightSide>
        <Image src={logo} alt="About our mission image" />
      </RightSide>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 500px;
  margin-top: 3rem;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const RightSide = styled.div`
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 5rem;
  text-transform: uppercase;
  color: white;

  @media (max-width: 990px) {
    font-size: 4rem;
  }

  @media (max-width: 420px) {
    font-size: 3rem;
  }
`;

const GreenText = styled.span`
  color: #24ca97;
`;

const PurpleText = styled.span`
  color: #9f2fd5;
`;

const Text = styled.p`
  font-size: 25px;
  color: white;
  line-height: 1.5;

  @media (max-width: 990px) {
    font-size: 1.5rem;
  }

  @media (max-width: 420px) {
    font-size: 1rem;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 1000px) {
    margin-top: 20px;
    width: 300px;
  }
  @media (max-width: 420px) {
    width: 200px;
  }
`;
