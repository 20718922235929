import styled from "styled-components";
import logo from "../assets/img/logo.png";

export default function Header() {
  const Logodiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    font-size: 1.5rem;
    color: white;
    position: relative; /* Keep it in the normal document flow */
    z-index: 0;
    width: 100%;
    padding: 1rem 0;
    margin-inline: 10px;

    img {
      width: 60px;
      height: 60px;
    }

    @media (max-width: 990px) {
      font-size: 1.2rem;

      img {
        width: 50px;
        height: 50px;
      }
    }

    @media (max-width: 650px) {
      font-size: 1rem;

      img {
        width: 45px;
        height: 45px;
      }
    }

    @media (max-width: 420px) {
      font-size: 1rem;
      img {
        width: 40px;
        height: 40px;
      }
    }
  `;

  return (
    <header>
      <Logodiv>
        <img className="logo" src={logo} alt="Saga DAO logo" />
        <h1>SAGA DAO</h1>
      </Logodiv>
    </header>
  );
}
