import { useState } from "react";
import styled from "styled-components";

const Title = styled.h1`
  padding: 10px;
  font-size: 5rem;
  color: white;

  @media (max-width: 990px) {
    font-size: 4rem;
  }

  @media (max-width: 420px) {
    font-size: 3rem;
  }
`;

const Green = styled.span`
  color: #24ca97;
`;

const Purple = styled.span`
  color: #9f2fd5;
`;

const Form = styled.div`
  margin: 20px 0 20px 0;
  padding: 30px;
  width: calc(100% - 65px);
  background: #1d2529;
  border: 2px solid #24ca97;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  align-text: right;
`;

const GetTitle = styled.h2`
  width: 100%;
  @media (max-width: 990px) {
    font-size: 2.5rem;
  }

  @media (max-width: 420px) {
    font-size: 1.5rem;
  }
`;

const InputTitle = styled.h2`
  color: white;
  width: 100%;

  @media (max-width: 990px) {
    font-size: 1.5rem;
  }

  @media (max-width: 420px) {
    font-size: 1rem;
  }
`;

const Input = styled.input`
  margin-block: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  font-size: 1rem;
  color: inherit;
`;

const Textarea = styled.textarea`
  margin-block: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  font-size: 1rem;
  color: inherit;
  resize: none;
`;

const Button = styled.button`
  padding: 15px 80px;
  margin: 10px auto 0px -10px;
  background: #24ca97;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5); /* Updated box-shadow */
  }

  @media (max-width: 990px) {
    margin: 10px 0px 0px -10px;
    padding: 15px 40px;
  }
`;

export default function GetContact() {
  const [email, setEmail] = useState("");
  const [ideas, setIdeas] = useState("");

  const handleForm = async () => {
    console.log(email);
    console.log(ideas);
    setEmail("");
    setIdeas("");
  };

  return (
    <>
      <Title>
        <Green>INTRESTED</Green> IN <Purple>SAGA DAO</Purple>’S CAPABILITIES?
      </Title>
      <Form>
        <GetTitle>
          <Green>GET IN CONTACT</Green>
        </GetTitle>
        <InputTitle>Your Email</InputTitle>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputTitle>Your Ideas</InputTitle>
        <Textarea
          type="text"
          value={ideas}
          rows={5}
          onChange={(e) => setIdeas(e.target.value)}
        />
        <Button onClick={handleForm}>GET IN CONTACT</Button>
      </Form>
    </>
  );
}
