import styled from "styled-components";
import Sphere from "../assets/img/Sphere.png";

const Background = styled.div`
  width: 100%;
  margin-top: -15vh;
  height: 1100px; /* Adjust height to account for header */
  position: relative;
  z-index: 1000;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${Sphere}) no-repeat center center;
    background-size: cover;
    opacity: 0.4;
    rotate: 180deg;
    z-index: 1;
  }
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 990px) {
    height: 100vh; /* Adjust height to account for header */
  }

  @media (max-width: 750px) {
    margin-top: -10vh;
    height: 100vh; /* Adjust height to account for header */
  }
`;

const MainText = styled.h1`
  z-index: 2;
  margin-inline: 90px;
  font-size: 6rem;
  color: white;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  user-select: none;

  @media (max-width: 990px) {
    font-size: 5rem;
  }

  @media (max-width: 750px) {
    margin-inline: 20px;
    font-size: 4rem;
  }

  @media (max-width: 455px) {
    font-size: 3rem;
    margin-inline: 5px;
  }
`;

const Green = styled.span`
  color: #24ca97;
  font-weight: 300;
`;

export default function Main() {
  return (
    <Background>
      <MainText>
        Unlock Perks, <br /> <Green>Join</Green> the Saga.{" "}
        <Green>Unleash</Green> the <Green>Power</Green> of Solana Mobile.
      </MainText>
    </Background>
  );
}
