import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main-section";
import About from "./components/about-section";
import Social from "./components/social-section";
import Council from "./components/council-section";
import GetContact from "./components/get-in-contact";
import Footer from "./components/footer";

import "@fontsource/blinker/200.css";
import "@fontsource/blinker/300.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <About />
      <Social />
      <Council />
      <GetContact />
      <Footer />
    </div>
  );
}

export default App;
