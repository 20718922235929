import styled from "styled-components";
import x from "../assets/img/X.png";
import discord from "../assets/img/discord.png";
import calendly from "../assets/img/calendly.png";

export default function Social() {
  return (
    <Wrapper>
      <Heading>Join Our Community</Heading>
      <Rectangle>
        <IconWrapper>
          <TextIcon>SOCIAL</TextIcon>
          <SocialIcon>
            <a href="https://calendly.com/sagadao" target="_blank" rel="noopener noreferrer">
              <ImageIcon src={calendly} alt="Calendly" />
            </a>
          </SocialIcon>
          <SocialIcon>
            <a href="https://x.com/SagaMobileDAO" target="_blank" rel="noopener noreferrer">
              <ImageIcon src={discord} alt="Discord" />
            </a>
          </SocialIcon>
          <SocialIcon>
            <a href="https://discord.gg/sagadao" target="_blank" rel="noopener noreferrer">
              <ImageIcon src={x} alt="Twitter" />
            </a>
          </SocialIcon>
        </IconWrapper>
      </Rectangle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  width: 100%;

  @media (max-width: 1000px) {
    margin-top: 3rem;
  }

  @media (max-width: 550px) {
    margin-bottom: -100px;
  }
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: white;
  margin-bottom: 20px;
`;

const Rectangle = styled.div`
  width: 500px;
  height: 120px;
  border: 2px solid #9f2fd5;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 10px;

  @media (max-width: 550px) {
    width: 400px;
    height: 100px;
  }

  @media (max-width: 420px) {
    width: 300px;
    height: 80px;
  }
`;

const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  width: auto;
  gap: 50px;

  @media (max-width: 550px) {
    gap: 15px;
  }

  @media (max-width: 420px) {
    width: auto;
  }
`;

const SocialIcon = styled.div`
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

export const ImageIcon = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;

  @media (max-width: 420px) {
    width: 35px;
    height: 35px;
  }
`;

const TextIcon = styled.div`
  font-size: 40px;
  color: #24ca97;
  font-size: 3rem;
  transition: transform 0.3s ease;

  @media (max-width: 420px) {
    font-size: 30px;
  }
`;
